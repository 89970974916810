<template>
  <!-- 筛选项 -->
  <div class="filter-items">
    <a-row>
      <a-col>
        <span>性别：</span>
        <a-select placeholder="全部" style="width: 100px" v-model="params.sex" allowClear>
          <a-select-option value="男">男</a-select-option>
          <a-select-option value="女">女</a-select-option>
        </a-select>
      </a-col>
      <a-col>
        <span>年龄：</span>
        <a-input v-model="params.minAge" />~
        <a-input v-model="params.maxAge" />
      </a-col>
      <a-col>
        <span>主要诊断：</span>
        <a-select show-search option-filter-prop="children" :filter-option="filterOption" placeholder="请选择"
          style="width: 300px" v-model="params.diagnosisId" allowClear>
          <a-select-option v-for="(item, index) in mainDiagnosisList" :key="index" :value="item.mainDiagnosisId">
            {{ item.type }}/{{ item.department }}/{{ item.diagnosisName }}</a-select-option>
        </a-select>
      </a-col>
      <a-col>
        <span>在ICU天数：</span>
        <a-input v-model="params.minIcuDays" />~
        <a-input v-model="params.maxIcuDays" />
      </a-col>
      <a-col>
        <a-date-picker v-model="selectedRange[0]" :disabled-date="disabledStartDate" show-time placeholder="请选择开始时间"
          @change="onStartChange" value-format="YYYY-MM-DD HH:mm:ss" /> ~
        <a-date-picker v-model="selectedRange[1]" :disabled-date="disabledEndDate" show-time placeholder="请选择结束时间"
          @change="onEndChange" value-format="YYYY-MM-DD HH:mm:ss" />
      </a-col>
      <a-col v-if="!isHospital">
        <a-select placeholder="请选择医院" style="width: 180px" v-model="params.hospitalId" :allow-clear="false" allowClear>
          <a-select-option v-for="(item, index) in hospitalList" :key="index" :value="item.hospitalId">
            {{ item.name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col>
        <a-button @click="search" type="primary">查询</a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>

import moment from "moment";
import api from "@/api/index.js";
import storage from "../../storage/storage.js";

export default {
  components: {},
  data() {
    return {
      isHospital: false,
      params: {
        sex: '',
        minAge: '',
        maxAge: '',
        diagnosisId: undefined,//主要诊断
        hospitalId: undefined,
        minIcuDays: null,
        maxIcuDays: null,
      },
      selectedRange: ['', ''],
      mainDiagnosisList: [],
      hospitalList: storage.getItem("hospitalList")
    };
  },
  mounted() {
    let users = storage.getItem("users");
    if (users && users.hospitalId) {
      this.isHospital = true;
      this.params.hospitalId = users.hospitalId
    } else {
      this.isHospital = false;
    }

    this.getMainDiagnosis();

    this.$set(this.selectedRange, 1, moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
    this.$set(this.selectedRange, 0, moment(new Date() - 7 * 24 * 60 * 60 * 1000).format(
      'YYYY-MM-DD HH:mm:ss'
    ))

    this.search();
  },
  methods: {
    getMainDiagnosis() {
      api
        .getMainDiagnosis()
        .then(({ data }) => {
          this.mainDiagnosisList = data
        });
    },
    disabledStartDate(current) {
      if (!this.selectedRange[1]) {
        return false;
      }

      const endDate = new Date(this.selectedRange[1]);
      const startDate = new Date(endDate);
      startDate.setDate(endDate.getDate() - 14);

      return current < startDate || current > endDate;
    },
    disabledEndDate(current) {
      if (!this.selectedRange[0]) {
        return false;
      }

      const startDate = new Date(this.selectedRange[0]);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 14);

      return current < startDate || current > endDate;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    search() {
      let params = {
        data: this.params,
        selectedRange: this.selectedRange
      }
      this.$emit('search', params);
    },
    onStartChange(date, dateString) {
      this.selectedRange[0] = dateString
    },
    onEndChange(date, dateString) {
      this.selectedRange[1] = dateString
    },
  },
};
</script>
<style scoped lang="less">
.filter-items {
  background: #fff;
  border-radius: 0 0 30px 30px;
  padding: 30px 30px 15px;
  margin-bottom: 15px;

  .ant-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .ant-col {
      margin-right: 20px;
      margin-bottom: 15px;

      .ant-input {
        width: 68px;
      }

      .ant-btn-primary {
        background: #2468f2;
      }
    }
  }
}
</style>